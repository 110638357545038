import { AbstractErrorHandler } from '~/utils/errorHandlers/abstractErrorHandler';

export enum EErrorType {
  NOT_FOUND = 'NOT_FOUND',
  NUXT_SERVER_ERROR = 'NUXT_SERVER_ERROR',
  BACKEND_SERVER_ERROR = 'BACKEND_SERVER_ERROR',
}

/**
 * @description Обработчик ошибок.
 */
class CustomErrorHandler extends AbstractErrorHandler {
  protected errors: Record<
    EErrorType,
    { statusCode: number; statusMessage: string; message?: string }
  > = {
    [EErrorType.NOT_FOUND]: {
      statusCode: 404,
      statusMessage: 'Page not found',
      message: 'The page you are trying to access does not exist or has been deleted',
    },

    [EErrorType.NUXT_SERVER_ERROR]: {
      statusCode: 500,
      statusMessage: 'Internal server error',
    },

    [EErrorType.BACKEND_SERVER_ERROR]: {
      statusCode: 500,
      statusMessage: 'Internal server error',
    },
  };

  public throwError(
    errorKey: EErrorType,
    customStatusMessage?: string,
    customMessage?: string,
  ): never {
    const error = this.errors[errorKey];

    const isShowMessage =
      useRuntimeConfig().public.BUILD_MODE === 'development' || errorKey === EErrorType.NOT_FOUND;

    throw createError({
      statusCode: error.statusCode,
      statusMessage: customStatusMessage || error.statusMessage,
      message: isShowMessage ? customMessage || error.message : ' ',
    });
  }
}

export const errorHandler = new CustomErrorHandler();
